/* stylelint-disable -- styles.module.css included to test, tailwind incoming */
@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* ----------- LoaderContainer ---------- */
.sitecore-loader-container {
  align-items: center;
  display: flex;
  flex: 1;
}

/* ------------ LoaderAnimation ---------- */
.sitecore-loader-animation {
  animation: rotate-animation 2s linear infinite;
  display: block;
  fill: var(--sdc-palette-primary-main);
  height: 50px;
  margin: auto;
  width: 50px;
}
