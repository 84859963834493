@font-face {
font-family: '__Poppins_c236f4';
src: url(/_next/static/media/0b025add53ccd909-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__Poppins_c236f4';
src: url(/_next/static/media/72f1226e8655427c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__Poppins_c236f4';
src: url(/_next/static/media/8968a398acf6d7ac-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
}@font-face {font-family: '__Poppins_Fallback_c236f4';src: local("Arial");ascent-override: 90.85%;descent-override: 30.28%;line-gap-override: 8.65%;size-adjust: 115.57%
}.__className_c236f4 {font-family: '__Poppins_c236f4', '__Poppins_Fallback_c236f4'
}.__variable_c236f4 {--Poppins: '__Poppins_c236f4', '__Poppins_Fallback_c236f4'
}

@font-face {
font-family: '__NotoSans_3599c8';
src: url(/_next/static/media/e1aa8461bedb219f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__NotoSans_3599c8';
src: url(/_next/static/media/e17e13b32f7feb5d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}@font-face {font-family: '__NotoSans_Fallback_3599c8';src: local("Arial");ascent-override: 100.41%;descent-override: 27.52%;line-gap-override: 0.00%;size-adjust: 106.47%
}.__className_3599c8 {font-family: '__NotoSans_3599c8', '__NotoSans_Fallback_3599c8'
}.__variable_3599c8 {--Noto-Sans: '__NotoSans_3599c8', '__NotoSans_Fallback_3599c8'
}

@font-face {
font-family: '__NotoSerif_9345cd';
src: url(/_next/static/media/e9343ea7fded6c43-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__NotoSerif_Fallback_9345cd';src: local("Arial");ascent-override: 98.10%;descent-override: 26.89%;line-gap-override: 0.00%;size-adjust: 108.97%
}.__className_9345cd {font-family: '__NotoSerif_9345cd', '__NotoSerif_Fallback_9345cd';font-weight: 400
}.__variable_9345cd {--Noto-Serif: '__NotoSerif_9345cd', '__NotoSerif_Fallback_9345cd'
}

@keyframes splide-loading{0%{transform:rotate(0)}to{transform:rotate(1turn)}}.splide__track--draggable{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.splide__track--fade>.splide__list>.splide__slide{margin:0!important;opacity:0;z-index:0}.splide__track--fade>.splide__list>.splide__slide.is-active{opacity:1;z-index:1}.splide--rtl{direction:rtl}.splide__track--ttb>.splide__list{display:block}.splide__container{box-sizing:border-box;position:relative}.splide__list{backface-visibility:hidden;display:flex;height:100%;margin:0!important;padding:0!important}.splide.is-initialized:not(.is-active) .splide__list{display:block}.splide__pagination{align-items:center;display:flex;flex-wrap:wrap;justify-content:center;margin:0;pointer-events:none}.splide__pagination li{display:inline-block;line-height:1;list-style-type:none;margin:0;pointer-events:auto}.splide:not(.is-overflow) .splide__pagination{display:none}.splide__progress__bar{width:0}.splide{position:relative;visibility:hidden}.splide.is-initialized,.splide.is-rendered{visibility:visible}.splide__slide{backface-visibility:hidden;box-sizing:border-box;flex-shrink:0;list-style-type:none!important;margin:0;position:relative}.splide__slide img{vertical-align:bottom}.splide__spinner{animation:splide-loading 1s linear infinite;border:2px solid #999;border-left-color:transparent;border-radius:50%;bottom:0;contain:strict;display:inline-block;height:20px;left:0;margin:auto;position:absolute;right:0;top:0;width:20px}.splide__sr{clip:rect(0 0 0 0);border:0;height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.splide__toggle.is-active .splide__toggle__play,.splide__toggle__pause{display:none}.splide__toggle.is-active .splide__toggle__pause{display:inline}.splide__track{overflow:hidden;position:relative;z-index:0}
/* stylelint-disable -- styles.module.css included to test, tailwind incoming */
@keyframes styles_rotate-animation__syzZ2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* ----------- LoaderContainer ---------- */
.styles_sitecore-loader-container__fTOhs {
  align-items: center;
  display: flex;
  flex: 1;
}

/* ------------ LoaderAnimation ---------- */
.styles_sitecore-loader-animation__Yg4BI {
  animation: styles_rotate-animation__syzZ2 2s linear infinite;
  display: block;
  fill: var(--sdc-palette-primary-main);
  height: 50px;
  margin: auto;
  width: 50px;
}

/* stylelint-disable -- styles.module.css included to test, tailwind incoming */
@keyframes styles_rotate-animation__yNPHI {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* ----------- LoaderContainer ---------- */
.styles_sitecore-loader-container__OboLu {
  align-items: center;
  display: flex;
  flex: 1;
}

/* ------------ LoaderAnimation ---------- */
.styles_sitecore-loader-animation__PIz31 {
  animation: styles_rotate-animation__yNPHI 2s linear infinite;
  display: block;
  fill: var(--sdc-palette-primary-main);
  height: 50px;
  margin: auto;
  width: 50px;
}

/* stylelint-disable -- styles.module.css included to test, tailwind incoming */
@keyframes styles_rotate-animation__sy1tk {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* ----------- LoaderContainer ---------- */
.styles_sitecore-loader-container__tqL7F {
  align-items: center;
  display: flex;
  flex: 1;
}

/* ------------ LoaderAnimation ---------- */
.styles_sitecore-loader-animation__fYiAw {
  animation: styles_rotate-animation__sy1tk 2s linear infinite;
  display: block;
  fill: var(--sdc-palette-primary-main);
  height: 50px;
  margin: auto;
  width: 50px;
}

